@import '../../global.scss';

.toolspack {
    background-color: rgb(13, 13, 49);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 2s ease;

    .arrow {
        height: 50px;
        position: absolute;
        cursor: pointer;
        transition: all 0.3s ease-out;

        @include mobile {
            display: none;
        }

        &.left {
            left: 100px;
            transform: rotateY(180deg);

            @include tablet {
                left: 5px;
            }

            &:hover {
                transform: scale(1.2) rotateY(180deg);
            }
        }

        &.right {
            right: 100px;

            @include tablet {
                right: 5px;
            }

            &:hover {
                transform: scale(1.2);
            }
        }
    }

    .slider {
        height: 470px;
        display: flex;
        position: absolute;
        left: 0;
        transition: all 1s ease-out;
        overflow: hidden;

        @include lTablet {
            height: 400px;
        }

        @include tablet {
            height: 280px;
        }

        @include mobile {
            height: 100vh;
            flex-direction: column;
            justify-content: center;
        }

        .container {
         width: 100vw;
         display: flex;
         align-items: center;
         justify-content: center;
         overflow: hidden;

            .item {
                width: 850px;
                height: 100%;
                background-color: white;
                border-radius: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                transition: background-color 2s ease;


                @include lTablet {
                    width: 600px;
                    height: 350px;
                    margin: 20px 0;
                }

                @include tablet {
                    width: 360px;
                    height: 200px;
                    margin: 20px 0;
                }
                

                @include mobile {
                    width: 90%;
                    height: 190px;
                    margin: 20px 0;
                }

                @include sMobile {
                    height: 90%;
                }

                .left {
                    flex: 3;
                    height: 80%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 10px;

                    @include lTablet {
                        flex: 5;
                    }

                    @include tablet {
                        flex: 6;
                        height: 90%;
                    }

                    @include mobile {
                        flex: 6;
                        height: 90%;
                    }

                    .left-container {
                        width: 90%;
                        height: 70%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        @include lTablet {
                            height: 80%;
                        }

                        @include tablet {
                            height: 90%;
                        }

                        @include mobile {
                            height: 90%;
                        }

                        .img-container {
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            background-color: rgb(245, 179, 155);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            
                            @include tablet {
                                width: 30px;
                                height: 30px;
                            }

                            @include mobile {
                                width: 20px;
                                height: 20px;
                            }

                            img {
                                width: 25px;
                            }
                        }

                        h2 {
                            font-size: 20px;

                            @include lTablet {
                                left: 17px;
                            }

                            @include tablet {
                                font-size: 13px;
                            }

                            @include mobile {
                                font-size: 12px;
                            }
                        }

                        p {
                            font-size: 11px;
                        }

                        span {
                            font-size: 12px;
                            font-weight: 600;
                            text-decoration: underline;
                        }
                    }
                }
                .right {
                    flex: 14;
                    height: 100%;
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    flex-wrap: wrap;
                    overflow: hidden;

                    .item {
                        width: 180px;
                        height: 120px;
                        border-radius: 20px;
                        border: 1px solid rgb(240, 239, 239);
                        margin: 10px ;
                        position: relative;

                        @include lTablet {
                            width: 100px;
                            height: 70px;
                            margin: 5px;
                            border-radius: 5px;
                        }

                        @include tablet {
                            width: 90px;
                            height: 40px;
                            margin: 5px;
                            border-radius: 5px;
                        }

                        @include mobile {
                            width: 40px;
                            height: 30px;
                            margin: 5px;
                            border-radius: 5px;
                        }

                        img {
                            width: 100%;
                            height: 100%;
                            overflow: hidden;
                            object-fit: contain;
                        }
                    }
                }

            }
        }
    }

    &.dark {
        
        background-color: rgb(13, 13, 49);
        .slider {
            .container {
                .item {
                    background-color: rgb(78, 67, 128);
                    color: white;
                }

                .right {
                    .item {
                        background-color: rgb(161, 151, 204);
                    }
                }
            }
        }
    }
}