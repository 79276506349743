@import '../../global.scss';

.portfolio-list {
    font-size: 14px;
    margin-right: 50px;
    padding: 7px;
    border-radius: 10px;
    cursor: pointer;

    @include mobile {
        margin-right: 10px;
        font-size: 12px;
        max-height: 20px;
    }

    &.active {
        background-color: $mainColor;
        color: white;
        transition: all 0.2s ease;
    }

    &.dark {
        color: white;
        transition: background-color 0.5s ease;

        &.active {
            background-color: white;
            color: $mainColor;
            transition: all 0.2s ease;
        }
    }
}