@import '../../global.scss';

.tsparticles-canvas-el {
    position: absolute;
    height: 100vh;
    width: 100vw;
}

.land {
    width: 100vw;
    height: 100vh;

    .text {
        display: flex;
        width: 100vw;
        height: 100vh;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .h11 {
            @include mobile {
                font-size: 25px;
            }
        }

        .h22 {
            @include mobile {
                font-size: 25px;
            }
            
        }
    }
}