@import '../../global.scss';

.intro {
    background-color: white;
    display: flex;
    transition: background-color 2s ease;
    min-height: 530px;

    @include tablet {
        flex-direction: column;
        align-items: center;
    }

    @include mobile {
        flex-direction: column;
        align-items: center;
    }
    
    .left {
        flex: 0.5;
        overflow: hidden;

        @include sMobile {
            flex: 0.45;
        }

        .imgContainer {
            margin-top: 10px;
            width: 900px;
            height: 900px;
            border-radius: 50%;
            display:  flex;
            align-items: flex-start;
            justify-content: center;
            float: right;
            overflow: hidden;

            @include tablet {
                width: 1000px;
                align-items: flex-end;
            }

            @include mobile {
                width: 900px;
                height: 900px;
                align-items: flex-start;
            }

            @include fourK {
                height: 100%;
            }

            @include sMobile {
                width: 650px;
                height: 650px;
            }
            

            img {
                @include mobile {
                    height: 70%;
                }

                @include fourK {
                    height: inherit;
                }
            }
        }
    }

    .right {
        flex: 0.5;
        opacity: 0.9;

        @include mobile {
            flex: 0.65;
        }

        @include sMobile {
            flex: 0.75;
        }

        .bg-animation {

            @include tablet {
                visibility: hidden;
                opacity: 0;
            }

            @include mobile {
                visibility: hidden;
                opacity: 0;
            }
        }

        .wrapper {
            width: 100%;
            height: 100%;
            padding-left: 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: relative;

            @include tablet {
                background: none;
                padding-left: 0;
                align-items: center;
            }

            @include mobile {
                background: none;
                padding-left: 0;
                align-items: center;
                justify-content: start;
            }

            h1 {
                font-size: 60px;
                margin: 10px 0;

                @include tablet {
                    font-size: 50px;
                    margin: 0;
                }

                @include mobile {
                    font-size: 35px;
                    margin: 0;
                }

                @include sMobile {
                    font-size: 35px;
                    margin-top: 0
                }
            }

            h2 {
                font-size: 35px;

                @include tablet {
                    font-size: 25px;
                    margin-top: 10px;
                }

                @include mobile {
                    font-size: 22px;
                    margin-top: 10px;
                }

                @include sMobile {
                    font-size: 22px;
                    margin-top: 10px;
                }
            }

            h3 {
                font-size: 30px;
                margin-bottom: 10px;
                color: rgb(119, 83, 89);

                @include tablet {
                    font-size: 24px;
                }

                @include mobile {
                    font-size: 17px;
                }
                
                span {
                    font-size: inherit;
                    color: #d6469f;
                }

                .ityped-cursor {
                    animation: blink 1s infinite;
                }

                @keyframes blink {
                    50% {
                        opacity: 1;
                    }
                    100% {
                        opacity: 0;
                    }
                }
            }

            h4 {
                font-size: 25px;
                margin-top: 15px;
                color: rgb(119, 83, 89);

                @include mobile {
                    font-size: 17px;
                    margin-top: 10px;
                }

                @include sMobile {
                    font-size: 14px;
                    margin-top: 9px;
                }
            }

            .socials {
                display: flex;
                overflow: hidden;

                @include mobile {
                    height: 22px;
                }

                a {
                    overflow: hidden;
                    color: rgb(161, 104, 111);
                    margin-right: 12px;

                    svg {
                        @include mobile {
                            width: 21px;
                            height: 21px;
                        }
                    }
                }
            }

            ul {
                margin-top: 10px;
                padding: 0;
                list-style: none;

                @include tablet {
                    padding-left: 20px;
                }

                @include mobile {
                    margin-top: 5px;
                    padding-left: 10px;
                }

                li {
                    list-style-type: none;
                    margin-bottom: 1rem;
                    background: url('../../assests/svgs/point.svg') no-repeat left top;
                    padding: 0px 0px 3px 30px;
                    background-position-x: 2px;
                    background-position-y: -2px;

                    @include tablet {
                        margin-bottom: 0.7rem;
                    }

                    @include mobile {
                        margin-bottom: 0.5rem;
                        font-size: 12px;
                    }
                }
            }

            .colors {
                height: 400px;
                width: 400px;
                position: absolute;
                background: url('./../../assests/images/colors.png') no-repeat;
                background-size: 400px 400px;
                bottom: 0;
                right: 0;
                opacity: 0.3;

                @include mobile {
                    height: 0px;
                    width: 0px;
                    visibility: hidden;
                    display: none;
                }

                .attribution {
                    position: absolute;
                    text-decoration: none;
                    opacity: 0.7;
                    color: green;
                    bottom: 0;
                }
            }
        }

        .bottomLink {
            position: absolute;
            bottom: 15px;
            left: 60%;
            color: green;

            @include tablet {
                left: 48%;
            }

            @include mobile {
                left: 40%;
                bottom: 100px;
            }

            svg {
                width: 60px;
                animation: arrowBlink 2s infinite;
            }

            @keyframes arrowBlink {
                100% {
                    opacity: 0;
                }
            }
        }
    }

    &.dark {
        background-color: $mainColor;

        .right {
            h1 {
                color: white;
            }
            h2 {
                color: white;
            }
            h3 {
                color: rgb(190, 60, 84);
            }
            h4 {
                color: rgb(190, 60, 84);
            }

            div {
                a {
                    color: rgb(190, 60, 84);
                }
            }
            ul {
                li {
                    color: rgb(180, 180, 180);
                }
            }

            .socials {
                a {
                    color: rgb(212, 197, 199);
                }
            }
        }

        .bottomLink {
            svg {
                fill: white;
            }
        }

        .attribution {
            color: pink !important;
        }
    }
}