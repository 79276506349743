@import '../../global.scss';

.topbar {
    width: 100%;
    height: 70px;
    background-color: white;
    color: $mainColor;
    position: fixed;
    top: 0;
    z-index: 3;
    transition: all 2s ease;

    @include mobile {
        height: 50px;
    }

    .wrapper {
        padding: 10px 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include mobile {
            padding: 5px 30px 2px 30px;
        }

        @include sMobile {
            padding: 5px 30px 0px 30px;
        }

        .left {
            display: flex;
            align-items: center;

            .logo {
                font-size: 40px;
                font-weight: 700;
                text-decoration: none;
                color: inherit;
                margin-right: 40px;

                @include mobile {
                    font-size: 36px;
                }

                @include sMobile {
                    font-size: 35px;
                }
            }

            .itemContainer {
                display: flex;
                align-items: center;
                margin-right: 30px;
                
                @include mobile {
                    display: none;
                }

                a {
                    text-decoration: none;
                    color: inherit;
                    display: flex;
                    align-items: center;
                    margin-right: 30px;

                    svg {
                        width: 25px;
                        height: 25px;
                        margin-right: 5px;
                        transition: all 2s ease;
                    }
    
                    span {
                        font-size: 15px;
                        font-weight: 500;

                        @include phablet {
                            display: none;
                        }

                        @include tablet {
                            font-size: 12px;
                        }
                    }
                }
            }
        }

        .right {
            display: flex;
            justify-content: space-between;

            .darkMode {
                margin-right: 60px;
                transition: all 0.2s ease-in;

                &:hover {
                    cursor: pointer;
                    filter: invert(38%) sepia(79%) saturate(658%) hue-rotate(301deg) brightness(88%) contrast(95%);
                    transition: all 0.2s ease-in;
                }
            }

            .hamburger {
                width: 25px;
                height: 21px;
                display: flex;
                flex-direction: column;
                cursor: pointer;
                justify-content: space-between;
                
                span { 
                    width: 100%;
                    height: 3px;
                    border-radius: 7px;
                    background-color: $mainColor;
                    transform-origin: right;
                    transition: all 2s ease;
                }

                &:hover {
                    span {
                        filter: invert(38%) sepia(79%) saturate(658%) hue-rotate(301deg) brightness(88%) contrast(95%);
                        transition: all 0.2s ease;
                    }
                }
            }
        }
    }

    &.active {
        background-color: $mainColor;
        color: white;

        svg {
            fill: white;
        }

        .itemContainer {
            svg {
                fill: white;
            }
        }

        .hamburger {

            span {
                &:nth-child(1) {
                    background-color: white;
                    transform: rotate(-45deg);
                }
                &:nth-child(2) {
                    opacity: 0;
                }
                &:nth-child(3) {
                    background-color: white;
                    transform: rotate(45deg);
                }
            }
        }
    }

    &.dark {
        background-color: $mainColor;
        color: white;

        svg {
            fill: white;
        }

        .itemContainer {
            svg {
                fill: white;
            }
        }

        .hamburger {
            span {
                &.line {
                    background-color: white;
                }
            }
        }
    }

}