@import '../../global.scss';

.portfolio {
    background-color: rgb(180, 194, 211);
    background: linear-gradient(to bottom, rgb(253, 253, 253) 0%, rgb(204, 200, 224) 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 700px;
    transition: background-color 2s ease;

    h1 {
        font-size: 50px;
        margin-top: 50px;

        @include tablet {
            font-size: 35px;
        }

        @include mobile {
            font-size: 30px;
        }
    }

    ul {
        margin: 10px;
        padding: 0;
        list-style: none;
        display: flex;
        z-index: 1;

        @include tablet {
            flex-wrap: wrap;
            justify-content: center;
        }

        @include mobile {
            min-height: 90px;
            flex-wrap: wrap;
            justify-content: center;
        }
    }

    .container {
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        @include tablet {
            width: 90%;
        }

        @include mobile {
            width: 100%;
        }

        .item {
            width: 270px;
            height: 150px;
            border-radius: 20px;
            border: 1px solid $mainColor;
            margin: 10px 20px;
            position: relative;
            transition: all 0.5s ease;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;

            @include tablet {
                width: 160px;
                height: 110px;
            }

            @include mobile {
                width: 130px;
                height: 100px;
            }

            @include tMobile {
                width: 90px;
                height: 70px;
            }

            img {
                border-radius: 20px;
            }

           

            div {
                position: absolute;
                height: 100%;
                overflow: hidden;
                width: 100%;
                display: flex;
                align-items: center;
                flex-direction: column;
                text-align: center;

                h3 {
                    margin-top: 40px;

                    @include tablet {
                        margin-top: 30px;
                        font-size: 15px;
                    }

                    @include mobile {
                        margin-top: 30px;
                        font-size: 13px;
                    }

                    @include tMobile {
                        margin-top: 10px;
                        font-size: 9px;
                    }
                }

                div {
                    margin-top: 20px;
                    width: 100%;
                    display: flex;
                    justify-content: space-around;
                    flex-direction: row;

                    @include tablet {
                        margin-top: 27px;
                    }

                    @include mobile {
                        margin-top: 26px;
                    }

                    @include tMobile {
                        margin-top: 20px;
                    }

                    a {
                        text-decoration: none;
                        color: white;
                        padding: 5px;
                        background-color: rgb(75, 143, 122);
                        border-radius: 5px;
                        transition: all 0.3s ease;

                        @include mobile {
                            padding: 2.5px;
                        }

                        h4 {

                            @include tablet {
                                font-size: 12px;
                            }

                            @include mobile {
                                font-size: 10px;
                            }

                            @include tMobile {
                                font-size: 9px;
                            }
                            
                        }

                        &:hover {
                            transform: scale(1.05);
                        }
                    }
                }
            }
    
            img {
                width: 100%;
                height: 100%;
                overflow: hidden;
                object-fit: cover;
                z-index: 1;
            }
    
            &:hover {
                background-color: $mainColor;
    
                img {
                    opacity: 0.2;
                    z-index: 0;
                }
            }
        }        
    }

    &.dark {
        background-color: $mainColor;
        background: linear-gradient(to bottom, $mainColor 0%, rgb(13, 13, 49) 100%);

        h1 {
            color: white;
        }
    }
}